<template>
  <div class="setting-filiale-sci">
    <div class="box-setting-header ">
      <div class="title ml-4 mt-2">Gestion des sociétés SCI</div>
      <div>
        <b-button
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addSocieteFilialeSCIModal
          ><font-awesome-icon icon="plus" /> Ajouter une société SCI</b-button
        >
        <b-modal
          ref="addSocieteFilialeSCIModal"
          id="addSocieteFilialeSCIModal"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          no-close-on-backdrop
          modal-class="cutsom-modal-bootstrap  cutsom-modal-bootstrap-filiale-sci"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter une société SCI</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addSocieteFilialeSCIModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitSocieteFilialeSci"
                class="form-modal-custom-style"
              >
                <b-form-group class="mb-0 ml-3" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="SocietefilialeSciToAdd.type"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                    @change="onChangeTypeForm(SocietefilialeSciToAdd.type)"
                  ></b-form-radio-group>
                </b-form-group>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Nom de société"
                    label-for="filiale-sci-name"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-sci-name"
                      v-model="SocietefilialeSciToAdd.name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                  label="Adresse"
                  label-for="filiale-sci-adresse"
                  class="input-modal-champ mb-0"
                >
                  <b-form-textarea
                    autocomplete="off"
                    id="filiale-sci-adresse"
                    v-model="SocietefilialeSciToAdd.adresse"
                  ></b-form-textarea>
                </b-form-group>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Code postal"
                    label-for="filiale-sci-code_postal"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-sci-code_postal"
                      v-model="SocietefilialeSciToAdd.code_postal"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Ville"
                    label-for="filiale-sci-ville"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-sci-ville"
                      v-model="SocietefilialeSciToAdd.ville"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Email"
                    label-for="filiale-sci-email"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-sci-email"
                      v-model="SocietefilialeSciToAdd.email"
                      type="email"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="SocietefilialeSciToAdd.type === 'sci'"
                    label="Abréviation"
                    label-for="filiale-sci-email"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-sci-email"
                      v-model="SocietefilialeSciToAdd.abreviation"
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                  label="Siret"
                  label-for="filiale-sci-siret"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-siret"
                    v-model="SocietefilialeSciToAdd.siret"
                  ></b-form-input>
                </b-form-group>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Siren"
                    label-for="filiale-sci-siren_tva"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-sci-siren_tva"
                      v-model="SocietefilialeSciToAdd.siren_value"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Tva"
                    label-for="filiale-sci-siren_tva_value"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-sci-siren_tva_value"
                      v-model="SocietefilialeSciToAdd.tva_value"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Pays"
                    label-for="filiale-sci-pays"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-sci-pays"
                      v-model="SocietefilialeSciToAdd.pays"
                      :options="uniqueArrayOfCountries"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    v-if="SocietefilialeSciToAdd.type == 'filiale'"
                    label="Société SCI"
                    label-for="filiale-sci-pays"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-sci"
                      v-model="SocietefilialeSciToAdd.sci_id"
                      :options="getSciTypeSci"
                      class="b-form-select-raduis"
                      text-field="name"
                      value-field="id"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Iban"
                    label-for="filiale-sci-iban"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-sci-iban"
                      v-model="SocietefilialeSciToAdd.iban"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Fax"
                    label-for="filiale-sci-fax"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-sci-fax"
                      v-model="SocietefilialeSciToAdd.fax"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Téléphone"
                    label-for="filiale-sci-telephone"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-sci-telephone"
                      v-model="SocietefilialeSciToAdd.telephone"
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                  label="Description"
                  label-for="filiale-sci-description"
                  class="input-modal-champ mb-0"
                >
                  <b-form-textarea
                    required
                    autocomplete="off"
                    id="filiale-sci-description"
                    v-model="SocietefilialeSciToAdd.description"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  label="Logo"
                  label-for="file"
                  class="input-modal-champ-file mb-0"
                >
                  <b-form-file
                    v-model="SocietefilialeSciToAdd.image"
                    placeholder=""
                    drop-placeholder=""
                    accept="image/*"
                    @change="handleImageToAdd"
                  ></b-form-file>
                </b-form-group>
                <!-- end new property  -->
                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style mt-3">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>

    <b-modal
      ref="deleteSocieteFilialeSciModal"
      id="deleteSocieteFilialeSciModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer une societe SCI</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('deleteSocieteFilialeSciModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleSuprimerSocieteFilialeSci"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>
            Êtes-vous sur de vouloir supprimer cette soiciete sci
            <strong>
              {{
                societeFilialeSciToDelete ? societeFilialeSciToDelete.name : '-'
              }}
            </strong>
            ?
          </p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div
                v-if="getLoadingSettingSocieteFilialeSci"
                class="loading ml-2"
              >
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <div class="box-all-filter mb-2">
      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Type</span>
        </div>
        <b-form-select
          class="b-form-select-new-style width-filter"
          v-model="typeFilter"
          :options="optionsTypeForFitre"
          @change="onChangeType"
        ></b-form-select>
      </div>
      <div>
        <div
          v-if="getLoadingSettingSocieteFilialeSci"
          class=" chargement-message-calender ml-2"
        >
          Chargement en cours ...
        </div>
        <div class="error-message">
          <div v-if="getErrorSettingSocieteFilialeSci" class="error">
            <ul v-if="Array.isArray(getErrorSettingSocieteFilialeSci)">
              <li
                v-for="(e, index) in getErrorSettingSocieteFilialeSci"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorSettingSocieteFilialeSci }}</div>
          </div>
        </div>
      </div>
      <div class="box-end-position">
        <search
          :value="searchValue"
          @changeSearchValue="changeSearchValue"
          classToUse="search-style"
        />
      </div>
    </div>
    <div class="mb-5">
      <b-table-simple class="table-fiche m-0 p-0" responsive>
        <b-tbody>
          <b-tr class="title-ligne">
            <b-th class="newcolor">Societé</b-th>
            <b-th class="newcolor">Logo</b-th>
            <b-th class="newcolor">Abréviation</b-th>
            <b-th class="newcolor">Adresse</b-th>
            <b-th class="newcolor">Email</b-th>
            <b-th class="newcolor">Siret</b-th>
            <b-th class="newcolor">Siren</b-th>
            <b-th class="newcolor">Tva</b-th>
            <b-th class="newcolor">Code postal</b-th>
            <b-th class="newcolor">Ville</b-th>
            <b-th class="newcolor">Pays</b-th>
            <b-th class="newcolor">Iban</b-th>
            <b-th class="newcolor">Fax</b-th>
            <b-th class="newcolor">Téléphone</b-th>
            <b-th class="newcolor">Description</b-th>
            <b-th class="newcolor actions"></b-th>
          </b-tr>
          <template v-for="(item, index) in getSettingSocietefilialeSci">
            <b-tr :key="'filiale-sci' + index + item.id">
              <b-td>{{ item.name ? item.name : '-' }}</b-td>
              <b-td
                ><img
                  class="image-preview"
                  :src="baseURL + item.logo"
                  v-if="item && item.logo"
              /></b-td>
              <b-td>{{ item.abreviation ? item.abreviation : '-' }}</b-td>
              <b-td>{{ item.adresse }}</b-td>
              <b-td>{{ item.email }}</b-td>
              <b-td>{{ item.siret }}</b-td>
              <b-td>{{ item.siren_value }}</b-td>
              <b-td>{{ item.tva_value }}</b-td>
              <b-td>{{ item.code_postal }}</b-td>
              <b-td>{{ item.ville }}</b-td>
              <b-td>{{ item.pays }}</b-td>
              <b-td>{{ item.iban }}</b-td>
              <b-td>{{ item.fax ? item.fax : '-' }}</b-td>
              <b-td>{{ item.telephone }}</b-td>
              <b-td>
                <div
                  :id="'tooltip-target-' + index"
                  class="three-points-concats"
                >
                  {{ item.description ? item.description : '-' }}
                </div>
                <b-tooltip :target="'tooltip-target-' + index" triggers="hover">
                  {{ item.description }}
                </b-tooltip>
              </b-td>
              <b-td class="actions">
                <b-button
                  class="button-danger-style ml-2"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="deleteSocieteFilialeSci(item)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style ml-2"
                  size="sm"
                  variant="succes"
                  @click.prevent.stop="updateSocieteFilialeSci(item)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </b-td>
            </b-tr>
          </template>
          <b-tr v-if="getSettingSocietefilialeSci.length == 0">
            <b-td colspan="24"> Il n'y a aucun enregistrement à afficher</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <b-modal
      ref="updateSocieteFilialeSciModal"
      id="updateSocieteFilialeSciModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-societe-filiale-sci"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier une société Sci</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateSocieteFilialeSciModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template
          v-slot:body
          v-if="societeFilialeSciToUpdate && societeFilialeSciToUpdate != null"
        >
          <form
            @submit.prevent="handleSubmitUpdateSocieteFilialeSci"
            class="form-modal-custom-style"
          >
            <template
              v-if="
                societeFilialeSciToUpdate && societeFilialeSciToUpdate != null
              "
            >
              <b-form-group
                class="mb-0 ml-3"
                v-slot="{ ariaDescribedby }"
                v-if="
                  societeFilialeSciToUpdate && societeFilialeSciToUpdate.type
                "
              >
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="societeFilialeSciToUpdate.type"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                ></b-form-radio-group>
              </b-form-group>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Nom de société Sci"
                  label-for="filiale-sci-name"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-name"
                    v-model="societeFilialeSciToUpdate.name"
                  ></b-form-input>
                </b-form-group>
              </div>
              <b-form-group
                label="Adresse"
                label-for="filiale-sci-adresse"
                class="input-modal-champ mb-0"
              >
                <b-form-textarea
                  autocomplete="off"
                  id="filiale-sci-adresse"
                  v-model="societeFilialeSciToUpdate.adresse"
                ></b-form-textarea>
              </b-form-group>
              <div class="ModelCol mr-3">
                <b-form-group
                  v-if="societeFilialeSciToUpdate"
                  label="Code postal"
                  label-for="filiale-sci-code_postal"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-code_postal"
                    v-model="societeFilialeSciToUpdate.code_postal"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="societeFilialeSciToUpdate"
                  label="Ville"
                  label-for="filiale-sci-ville"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-ville"
                    v-model="societeFilialeSciToUpdate.ville"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Email"
                  label-for="filiale-sci-email"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-email"
                    v-model="societeFilialeSciToUpdate.email"
                    type="email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="abréviation"
                  label-for="filiale-sci-abr"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-abr"
                    v-model="societeFilialeSciToUpdate.abreviation"
                  ></b-form-input>
                </b-form-group>
              </div>

              <!-- new property  -->
              <b-form-group
                label="Siret"
                label-for="filiale-sci-siret"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="filiale-sci-siret"
                  v-model="societeFilialeSciToUpdate.siret"
                ></b-form-input>
              </b-form-group>

              <div class="ModelCol mr-3">
                <b-form-group
                  label="Siren"
                  label-for="filiale-sci-siren_tva"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-siren_value"
                    v-model="societeFilialeSciToUpdate.siren_value"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Tva "
                  label-for="filiale-sci-siren_tva_value"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-tva_value"
                    v-model="societeFilialeSciToUpdate.tva_value"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Pays"
                  label-for="filiale-sci-pays"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    autocomplete="off"
                    id="filiale-sci-pays"
                    v-model="societeFilialeSciToUpdate.pays"
                    :options="uniqueArrayOfCountries"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  v-if="societeFilialeSciToUpdate.type == 'filiale'"
                  label="Société SCI"
                  label-for="filiale-sci-pays"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    autocomplete="off"
                    id="filiale-sci"
                    v-model="societeFilialeSciToUpdate.sci_id"
                    :options="getSciTypeSci"
                    class="b-form-select-raduis"
                    text-field="name"
                    value-field="id"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Iban"
                  label-for="filiale-sci-iban"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-iban"
                    v-model="societeFilialeSciToUpdate.iban"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Fax"
                  label-for="filiale-sci-fax"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-fax"
                    v-model="societeFilialeSciToUpdate.fax"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Téléphone"
                  label-for="filiale-sci-telephone"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-sci-telephone"
                    v-model="societeFilialeSciToUpdate.telephone"
                    type="number"
                  ></b-form-input>
                </b-form-group>
              </div>
              <b-form-group
                label="Description"
                label-for="filiale-sci-description"
                class="input-modal-champ mb-0"
              >
                <b-form-textarea
                  autocomplete="off"
                  id="filiale-sci-description"
                  v-model="societeFilialeSciToUpdate.description"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group
                label="Logo Filiale"
                label-for="file"
                class="input-modal-champ-file mb-0"
              >
                <b-form-file
                  v-model="societeFilialeSciToUpdate.image"
                  placeholder=""
                  drop-placeholder=""
                  accept="image/*"
                  @change="handleImageToUpload"
                ></b-form-file>
              </b-form-group>
              <!-- end new property  -->
              <div class="actionModel">
                <div class="messageError">
                  <div v-if="error" class="error">
                    <ul v-if="Array.isArray(error)">
                      <li v-for="(e, index) in error" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ error }}</div>
                  </div>
                </div>
                <b-button type="submit" class="button-valide-style mt-3">
                  <span>
                    Valider
                    <div v-if="loading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </template>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import domain from '@/environment';
export default {
  name: 'Gestion-des-sociétés-SCI',
  data() {
    return {
      baseURL: domain,
      typeFilter: null,
      uniqueArrayOfCountries: [],
      options: [
        { text: 'SCI', value: 'sci' },
        { text: 'Filiale', value: 'filiale' }
      ],
      optionsTypeForFitre: [
        { text: 'Tous', value: null },
        { text: 'SCI', value: 'sci' },
        { text: 'Filiale', value: 'filiale' }
      ],
      SocietefilialeSciToAdd: {
        type: 'sci',
        name: null,
        siret: null,
        adresse: null,
        siren_value: null,
        tva_value: null,
        abreviation: null,
        image: null,
        ville: null,
        pays: null,
        sci_id: null,
        iban: null,
        fax: null,
        telephone: null,
        description: null,
        email: null,
        filiale_id: 20
      },
      error: null,
      loading: false,
      societeFilialeSciToDelete: null,
      societeFilialeSciToUpdate: null,
      societeSciFilial: null,
      searchValue: null
    };
  },
  methods: {
    ...mapActions([
      'addSettingSocietefilialeSci',
      'getAllSettingSocieteFilialeSci',
      'deleteSettingSocieteFilialeSci',
      'updateSettingSocieteFilialeSci',
      'getAllCountreies',
      'getAllSciTypeSci'
    ]),
    previewImage: function(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = () => {
          this.filialeToAdd.imageData = input.files[0];
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    handleImageToAdd(event) {
      this.SocietefilialeSciToAdd.image = event.target.files[0];
    },
    handleImageToUpload(event) {
      this.SocietefilialeSciToAdd.image = event.target.files[0];
    },
    // previewImageUpdate: function(event) {
    //   var input = event.target;
    //   if (input.files && input.files[0]) {
    //     var reader = new FileReader();
    //     reader.onload = () => {
    //       this.filialeToUpdate.imageData = input.files[0];
    //     };
    //     reader.readAsDataURL(input.files[0]);
    //   }
    // },
    resetModal() {
      this.SocietefilialeSciToAdd = {
        type: 'sci',
        name: null,
        siret: null,
        adresse: null,
        siren_value: null,
        tva_value: null,
        abreviation: null,
        image: null,
        ville: null,
        pays: null,
        image: null,
        sci_id: null,
        iban: null,
        fax: null,
        telephone: null,
        description: null,
        email: null,
        filiale_id: 20 // energie investe
      };
      this.error = null;
      this.loading = false;
      this.societeFilialeSciToDelete = null;
      this.societeFilialeSciToUpdate = null;
    },
    onChangeTypeForm(typeParam) {
      this.SocietefilialeSciToAdd = {
        type: typeParam,
        name: null,
        siret: null,
        adresse: null,
        civilite: null,
        siren_value: null,
        tva_value: null,
        logo: null,
        ville: null,
        abreviation: null,
        sci_id: null,
        pays: null,
        iban: null,
        fax: null,
        telephone: null,
        description: null,
        filiale_id: 20 // energie investe
      };
      this.error = null;
      this.loading = false;
      this.societeFilialeSciToDelete = null;
      this.societeFilialeSciToUpdate = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleSubmitSocieteFilialeSci() {
      this.loading = true;
      const response = await this.addSettingSocietefilialeSci(
        this.SocietefilialeSciToAdd
      );
      if (response.success) {
        this.error = null;
        this.loading = false;
        this.onChangeType;
        this.hideModal('addSocieteFilialeSCIModal');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    async handleSuprimerSocieteFilialeSci() {
      const response = this.deleteSettingSocieteFilialeSci(
        this.societeFilialeSciToDelete
      );
      if (response) {
        this.hideModal('deleteSocieteFilialeSciModal');
      }
    },
    deleteSocieteFilialeSci(item) {
      this.societeFilialeSciToDelete = item;
      this.$refs['deleteSocieteFilialeSciModal'].show();
    },
    async updateSocieteFilialeSci(filiale) {
      this.societeSciFilial = filiale;
      this.societeFilialeSciToUpdate = { ...filiale }; //ref

      this.$refs['updateSocieteFilialeSciModal'].show();
    },
    async handleSubmitUpdateSocieteFilialeSci() {
      this.loading = true;
      const response = await this.updateSettingSocieteFilialeSci({
        filiale: this.societeFilialeSciToUpdate,
        filiale_origin: this.societeSciFilial
      });
      if (response.success) {
        this.error = null;
        this.loading = false;
        this.hideModal('updateSocieteFilialeSciModal');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    async onChangeType() {
      await this.getAllSettingSocieteFilialeSci({
        type: this.typeFilter,
        filiale_id: 20 // energie investe
      });
      this.setLocalStorageParamsFilialesci();
    },
    async changeSearchValue(value) {
      this.searchValue = value;
      // await this.getAllSettingSocieteFilialeSci({
      //   type: this.typeFilter,
      //   search: this.searchValue
      // });
      this.setLocalStorageParamsFilialesci();
    },
    setLocalStorageParamsFilialesci() {
      localStorage.setItem(
        'Params-gestion-societe-filiale-sci',
        JSON.stringify({
          type: this.typeFilter,
          search: this.searchValue
        })
      );
    }
  },
  computed: {
    ...mapGetters([
      'getLoadingSettingSocieteFilialeSci',
      'getErrorSettingSocieteFilialeSci',
      'getSettingSocietefilialeSci',
      'getPaysList',
      'getSciTypeSci'
    ])
    // computeTypeSci() {
    //   let sciNames = this.getSciTypeSci.map(item => {
    //     return { value: item.id, text: item.name };
    //   });
    //   return sciNames;
    // }
  },
  components: {
    Card: () => import('../component/card.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue')
  },

  async mounted() {
    if (localStorage.getItem('Params-gestion-societe-filiale-sci')) {
      this.typeFilter = JSON.parse(
        localStorage.getItem('Params-gestion-societe-filiale-sci')
      ).type;
      this.searchValue = JSON.parse(
        localStorage.getItem('Params-gestion-societe-filiale-sci')
      ).search;
    } else {
      this.setLocalStorageParamsFilialesci();
    }

    await this.getAllSettingSocieteFilialeSci({
      type: this.typeFilter,
      filiale_id: 20 // energie investe
    });
    await this.getAllSciTypeSci();
    await this.getAllCountreies();
    let countryNames = [];
    this.getPaysList?.map(element => countryNames.push(element.nom));
    this.uniqueArrayOfCountries = [...new Set(countryNames)];
  }
};
</script>

<style scoped lang="scss">
.setting-filiale-sci {
  width: 100%;
  .button-danger-style,
  .button-succes-style {
    font-size: 9px;
    height: 20px;
    width: 20px;
    padding: 3px;
    margin: 3px;
    border-radius: 5px;
  }
  .border-raduis {
    border-radius: 50%;
  }
  .input-focus-responsable-depot {
    width: 95%;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dddde2;
    overflow: visible;
    text-overflow: ellipsis;
    outline: unset;
    padding: 4px;
    color: #5d5d5d;
    font-size: 11px;
    font-weight: 400;

    &:focus {
      background-color: #eeeef7;
    }
  }
  .input-focus-responsable-depot::placeholder {
    color: #c3c3d8;
    font-size: 9px;
  }
  .table-fiche {
    width: 100%;
    height: calc(100vh - 205px);
  }
  .newcolor {
    min-width: 100px;
  }
  .actions {
    position: sticky;
    right: -1px !important;
  }
  .three-points-concats {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  }
}
</style>
<style lang="scss">
.table-fiche::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}
.table-fiche {
  table {
    display: table !important;
  }
}

.table-fiche::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}

.table-fiche::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}
.custom-select-vue-component-style {
  .multiselect__tags {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dddde2;
  }

  .multiselect__input,
  .multiselect__single {
    background: transparent;
  }
}
.labes-depot-name {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-align: start;
  color: #2a2a2a;
  margin-left: 3%;
  margin-top: 38px;
  margin-bottom: 1px;
  width: 30%;
}
.custom-checkbox {
  align-self: center;
  margin-right: 10px;
}
.image-preview {
  position: relative;
  display: inline;
  max-width: 112px;
}
.cutsom-modal-bootstrap-filiale-sci .modal-dialog {
  top: 0%;
  max-width: 70%;
}
.width-filter {
  width: 110px;
}
</style>
